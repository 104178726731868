// import React from "react";
// import { useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Breadcrumb } from "antd";
// import { breadCrumbsJson as Data } from "utils/constants/SibarBarMenu";
// import Cookies from "js-cookie";
// import PropTypes from "prop-types";

// export const BreadcrumbComponent = ({ separator = ">" }) => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const hasUrlParameter = (url) => {
//     const paramRegex = /\/(.*Id=|.*id=|.*Id\/|.*id\/)/;
//     return paramRegex.test(url);
//   };

//   const createNestedLinks = ({ text, Links }) => {
//     const parts = text.split(" > ");
//     const links = Links.split("/").filter(Boolean);

//     return parts.map((part, index) => {
//       const isLast = index === parts.length - 1;
//       const isSecondLast = index === parts.length - 2;
//       const isHome = part === "Home";
//       const containsParam = hasUrlParameter(location.pathname);

//       const path = isHome ? "/home" : `/${links[index]}`;

//       if (containsParam) {
//         Cookies.set("breadcrumbHistory", path);
//       }

//       return (
//         <Breadcrumb.Item key={index}>
//           {isLast ? (
//             <span style={{ color: "#6E7C87" }}>{part}</span>
//           ) : isSecondLast && containsParam ? (
//             <span
//               className="hover:text-blue-500 text-[#0073EE] cursor-pointer"
//               style={{ color: "#0073EE", cursor: "pointer" }}
//               onClick={() => navigate(-1)}
//             >
//               {part}
//             </span>
//           ) : (
//             <a href={path} style={{ color: "#0073EE" }}>
//               {" "}
//               {part}
//             </a>
//           )}
//         </Breadcrumb.Item>
//       );
//     });
//   };

//   const ThemeColor = useSelector((state) => state?.common?.Theme);

//   return (
//     <div
//       className={`pt-2 pb-2 text-sm flex items-center ${
//         ThemeColor
//           ? "text-[#252C32] bg-[#FFFFFF] bread-board"
//           : "dashboard_bread_bg_color border"
//       }`}
//     >
//       <Breadcrumb
//         separator={separator}
//         style={{ fontSize: "14px", paddingLeft: "55px", color: "#0073EE" }}
//       >
//         {" "}
//         {Data.map(
//           (item, index) =>
//             location.pathname.startsWith(item.path) && (
//               <React.Fragment key={index}>
//                 {createNestedLinks(item)}
//               </React.Fragment>
//             )
//         )}
//       </Breadcrumb>
//     </div>
//   );
// };

// BreadcrumbComponent.propTypes = {
//   separator: PropTypes.string,
// };



import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import { breadCrumbsJson as Data } from "utils/constants/SibarBarMenu";
import PropTypes from "prop-types";

export const BreadcrumbComponent = ({ separator = ">" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbHistory, setBreadcrumbHistory] = useState([]);
  const ThemeColor = useSelector((state) => state?.common?.Theme);

  const hasUrlParameter = (url) => {
    const paramRegex = /\/(.*Id=|.*id=|.*Id\/|.*id\/)/;
    return paramRegex.test(url);
  };

  useEffect(() => {
    // Load breadcrumb history from sessionStorage on mount
    const storedBreadcrumbs = JSON.parse(
      sessionStorage.getItem("breadcrumbHistory") || "[]"
    );
    setBreadcrumbHistory(storedBreadcrumbs);
  }, []);

  useEffect(() => {
    // Retrieve programData from sessionStorage (if available) to include as params in breadcrumbs
    const programData = JSON.parse(sessionStorage.getItem("programData")) || {};
    const params = Object.keys(programData).length > 0 ? programData : {};

    const existsInHistory = breadcrumbHistory.some(
      (b) =>
        b.path === location.pathname &&
        JSON.stringify(b.params) === JSON.stringify(params)
    );

    if (!existsInHistory) {
      const updatedBreadcrumbs = [
        ...breadcrumbHistory,
        {
          path: location.pathname,
          search: location.search,
          params,
        },
      ];
      setBreadcrumbHistory(updatedBreadcrumbs);
      sessionStorage.setItem(
        "breadcrumbHistory",
        JSON.stringify(updatedBreadcrumbs)
      );
    }
  }, [location, breadcrumbHistory]);

  const handleBreadcrumbClick = (path) => {
    // Retrieve the corresponding breadcrumb from history
    const breadcrumb = breadcrumbHistory.find((b) => b.path === path && Object.keys(b.params).length > 0);

    // Use stored params from breadcrumb history or retrieve from session storage if missing

    const params =
      breadcrumb?.params ||
      JSON.parse(sessionStorage.getItem("programData")) ||
      {};
    const searchParams = new URLSearchParams(params).toString();
    const updatedPath = `${path}`;
    console.log(params)
    navigate(updatedPath, { state: params });
  };

  const createNestedLinks = ({ text, Links }) => {
    const parts = text.split(" > ");
    const links = Links.split("/").filter(Boolean);

    return parts.map((part, index) => {
      const isLast = index === parts.length - 1;
      const isSecondLast = index === parts.length - 2;
      const isHome = part === "Home";
      const containsParam = hasUrlParameter(location.pathname);

      const path = isHome ? "/home" : `/${links[index]}${location.search}`;

      return (
        <Breadcrumb.Item key={index}>
          {isLast ? (
            <span style={{ color: "#6E7C87" }}>{part}</span>
          ) : isSecondLast && containsParam ? (
            <span
              className="hover:text-blue-500 text-[#0073EE] cursor-pointer"
              style={{ color: "#0073EE", cursor: "pointer" }}
              onClick={() => handleBreadcrumbClick(path)}
            >
              {part}
            </span>
          ) : (
            <span
              className="hover:text-blue-500 text-[#0073EE] cursor-pointer"
              style={{ color: "#0073EE", cursor: "pointer" }}
              onClick={() => handleBreadcrumbClick(path)}
            >
              {part}
            </span>
          )}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <div
      className={`pt-2 pb-2 text-sm flex items-center ${ThemeColor
        ? "text-[#252C32] bg-[#FFFFFF] bread-board"
        : "dashboard_bread_bg_color border"
        }`}
    >
      <Breadcrumb
        separator={separator}
        style={{ fontSize: "14px", paddingLeft: "55px", color: "#0073EE" }}
      >
        {Data.map(
          (item, index) =>
            location.pathname.startsWith(item.path) && (
              <React.Fragment key={index}>
                {createNestedLinks(item)}
              </React.Fragment>
            )
        )}
      </Breadcrumb>
    </div>
  );
};

BreadcrumbComponent.propTypes = {
  separator: PropTypes.string,
};
