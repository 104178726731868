import AuthReducer from "./AuthReducer";
import CommonReducer from "./CommonReducer";
import queriesReducer from './queries.reducer';
import UserManagementReducer from "./UserManagementReducer";
import POPManagementReducer from "./POPManagementReducer";
import CategoryManagementReducer from "./CategoriesMngReducer";
import ActivityManagementReducer from "./ActivityManagement";
import ApiCallStatusReducer from "./ApiCallStatusReducer";
import MonitoringReducer from "./Monitoring";
import { combineReducers } from "redux";
import GameReducer from "./GameReducer";
import FiltersReducer from "./FiltersReducer";
import DDSFiltersReducer from "./DDSFilterReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import FinanceFiltersReducer from "./FinanceFilterReducer";
import AnnouncementReducer from "./Announcements";
import ReportingColumnReducer from "./ReportingColumnReducer";

const ddsFiltersPersistConfig = {
  key: 'DDsFilters',
  storage,
};

const filtersPersistConfig = {
  key: 'Filters',
  storage,
};

const CommonPersistConfig = {
  key: 'Common',
  storage,
};

const ColumnPersistConfig = {
  key: 'Column',
  storage,
};

const persistedDDSFiltersReducer = persistReducer(ddsFiltersPersistConfig, DDSFiltersReducer);
const persistedFiltersReducer = persistReducer(filtersPersistConfig, FiltersReducer);
const persistedCommonReducer = persistReducer(CommonPersistConfig, CommonReducer);
const persistedColumnReducer = persistReducer(ColumnPersistConfig, ReportingColumnReducer);

const rootReducer = combineReducers({
  auth: AuthReducer,
  common: persistedCommonReducer,
  UserManagement: UserManagementReducer,
  POPManagemnt: POPManagementReducer,
  CategoryManagement: CategoryManagementReducer,
  activtyManagement: ActivityManagementReducer,
  MonitoringManagement: MonitoringReducer,
  GameManagement: GameReducer,
  AnnouncementManagement: AnnouncementReducer,
  ApiCallStatusReducer: ApiCallStatusReducer,
  Queries: queriesReducer,
  FinanceFilters: FinanceFiltersReducer,
  Filters: persistedFiltersReducer, // Fixed typo
  DDsFilters: persistedDDSFiltersReducer,
  ReportingColumn: persistedColumnReducer
});

export default rootReducer;
