import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import { setCookie } from "services/session/cookies";
import { parseZonedDateTime } from "@internationalized/date";
import { MainMenu } from "utils/constants/SibarBarMenu";
import { Permissions } from "utils/constants/Permissions/features";
import { ChangeNavigation } from "store/actions/common";

export const errorMessages = (message) => {
  if (typeof message === "string") {
    return message;
  } else {
    let list = "";
    for (var i = 0; i < message?.length; i++)
      list += ` ${i + 1}- ${message[i]} `;
    return list;
  }
};

export const successMessage = (message) => {
  return toast.success(message);
};

export const logOut = () => {
  setCookie("user", "");
  setCookie("accessToken", "");
};

export const mapValues = (isEdit, data, currentRecord, dateType) => {
  const fields = cloneDeep(data);
  let editData = cloneDeep(currentRecord);
  if (isEdit && fields.length && editData && Object.keys(editData).length) {
    fields.map((item) => {
      const fieldName = item["xmlName"];
      if (item["fieldType"] === "select") {
        item["value"] = editData[fieldName];
      } else if (item["fieldType"] === "date") {
        if (dateType && dateType === "momentType") {
          item["value"] = editData[fieldName]
            ? moment(editData[fieldName], "DD-MM-YYYY").toDate()
            : "";
        } else {
          item["value"] = editData[fieldName]
            ? new Date(editData[fieldName])
            : "";
        }
      } else {
        item["value"] =
          editData[fieldName] && editData[fieldName] != "null"
            ? editData[fieldName]
            : "";
      }
    });
  }
  return fields;
};

export const mapAllValues = (isEdit, state, data) => {
  console.log("isEdit, state, data", isEdit, state, data);
};

export const registerRegex = {
  userName: "^[a-zA-Z ]+$",
  email: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
  mobileNumber: /^[0-9]{4}-[0-9]{7}$/,
  // phoneNumber: /^\+?[0-9\s\-\(\)]{7,13}$/,
  password: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  confirmPassword:
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  name: "^[a-zA-Z ]+$",
  firstName: "^[a-zA-Z ]+$",
  lastName: "^[a-zA-Z ]+$",
  longName: "^[a-zA-Z ]+$",
  // website: "(https?:)?([w-])+.{1}([a-zA-Z]{2,63})([/w-]*)",
  // domain: "(https?:)?([w-])+.{1}([a-zA-Z]{2,63})([/w-]*)",
  ntnNumber: /^[0-9]{7}-[0-9]{1}$/,
  // address: "^\\W*(\\w+(\\W+|$)){1,500}$",
  // cnic: /^[0-9-]{15,20}$/,
  idenityId: /^[0-9-]{15,20}$/,
  imei: /^\d{15}(,\d{15})*$/,
};

export const organizationRegex = {
  name: /^[a-zA-Z\s]{3,50}$/, // Only letters and spaces, between 3 and 50 characters
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Standard email format
  longName: /^[a-zA-Z0-9\s,.-]{3,100}$/, // Alphanumeric, spaces, and basic punctuation, between 3 and 100 characters
  domain: /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/, // Valid domain name (without TLD)
  website:
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}([\/\w .-]*)*\/?$/, // Valid URL with or without protocol
  ntnNumber: /^\d{7,15}$/, // Numeric, 7 to 15 digits
};


export const registerErrorMessageObj = {
  userName: "Please enter valid name",
  firstName: "Please enter valid name",
  lastName: "Please enter valid name",
  email: "Please enter valid email e.g@.com",
  mobileNumber: "Please enter valid mobile number",
  // phoneNumber: "Please enter valid phone number",
  password:
    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:",
  confirmPassword:
    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:",
  name: "Please enter valid name",
  longName: "Please enter valid long name  ",
  // cnic: "Please enter valid cnic including - or Number",
  idenityId: "Please enter valid cnic including - or Number",
  imei: "Please enter valid IMEI 8XXXXXXXXXXXXXX",
  ntnNumber: "Please enter valid NTN Number xxxxxxx-x",
};

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const queryParamsCreator = (params) => {
  let queryString = "";

  if (params && Object.keys(params).length) {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
      const value = params[key];

      if (value) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            value.forEach((item) => {
              searchParams.append(key, item);
            });
          }
        } else {
          searchParams.append(key, value);
        }
      }
    });

    queryString = searchParams.toString();
  }

  return queryString ? `?${queryString}` : "";
};


/**
 * Checks if a given time is within the last 15 minutes relative to the current time.
 * @param {string | Date} time - The time to check. Can be a valid date string or a Date object.
 * @returns {boolean} True if the given time is within the last 15 minutes, false otherwise.
 */
export const isTimeWithinLast15Minutes = (time) => {
  // Given time
  const givenTime = new Date(time);

  // Current time
  const currentTime = new Date();

  // Calculate 15 minutes ago
  const fifteenMinutesAgo = new Date(currentTime.getTime() - 15 * 60 * 1000);

  // Check if givenTime is within the 15-minute window
  return (givenTime >= fifteenMinutesAgo && givenTime <= currentTime);
};


export const getDateRange = (startDate, endDate) => {
  // Get user's local time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get user's local time in HH:MM format
  const today = new Date();
  const hours = String(today.getHours()).padStart(2, '0'); // Format hours
  const minutes = String(today.getMinutes()).padStart(2, '0'); // Format minutes
  const localTime = `${hours}:${minutes}`;

  // Format dates
  let minVALUE = `20${moment(startDate).format("YY-MM-DD")}`;
  let maxValue = `20${moment(endDate).format("YY-MM-DD")}`;

  // Create min and max date strings
  const minDateString = `${minVALUE}T${localTime}[${userTimeZone}]`;
  const maxDateString = `${maxValue}T${localTime}[${userTimeZone}]`;

  // Parse dates
  const minDate = parseZonedDateTime(minDateString);
  const maxDate = parseZonedDateTime(maxDateString);

  return { minDate, maxDate };
};



export const messsageStatusResponse = (myJson) => {
  if (myJson?.response) {
    const message = myJson?.response?.data?.message ?? "Something went wrong";
    const statusCode = myJson?.response?.data?.statusCode ?? 500;
    return { message, statusCode };
  } else {
    const message = myJson?.data?.message ?? "Something went wrong";
    const statusCode = myJson?.data?.statusCode ?? 500;
    return { message, statusCode };

  }
}


export const formatDateToDateMonth = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short' };
  return date.toLocaleDateString('en-US', options);
}

export const getDataString = (date) => {
  if (date) {
    return date && (typeof date === "string" ? date : date?.toISOString())
  } else {
    return null
  }
}

export const isArrayEmptyStrings = (arr) => {
  return Array.isArray(arr) && arr.length === 2 && arr.every((item) => item === '');
};

export const handleNavigation = (key, redirect, permissions, navigate, dispatch) => {
  const selectedItem = MainMenu.find((item) => item.key === key);
  if (selectedItem) {
    const { label, link, items } = selectedItem;
    const mappedItems = items
      ? items.map((item) => ({
        label: item.label,
        value: item.link,
        permission: item.permission,
      }))
      : [];
    let result = [{ label, value: link }];
    if (mappedItems.length > 0 && permissions.length > 0) {
      const checkPermissions = mappedItems.filter((item) => {
        return JSON.parse(permissions).includes(item.permission);
      });
      result = result.concat(checkPermissions);
    } else {
      result = result.concat(mappedItems);
    }
    setCookie("navItems", JSON.stringify(result));
    dispatch(
      ChangeNavigation(
        result
      )
    )
    return navigate(redirect);
  }
};

export const OnlyInclude = [
  Permissions.REPORTING_MANAGEMENT,
  Permissions.DDS_DS,
  Permissions.INSTORE_DS,
  Permissions.REPORTING__MANAGEMENT_SCREEN,
  Permissions.RECORD_RESPONSE_COLUMNS,
  Permissions.VIEW_RECORD_RESPONSE,
  Permissions.EDIT_RECORD_RESPONSE
];

export const mapToBoolean = (query) => {
  const trueValues = ["yes", "true", "on", "active"]; // Add more as needed
  const falseValues = ["no", "false", "off", "inactive"]; // Add more as needed

  if (trueValues.includes(query.toLowerCase())) return true;
  if (falseValues.includes(query.toLowerCase())) return false;

  return null; // Return null if the query doesn't match any mapping
};

export const checkImageURL = (url) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);

    img.src = url;
  });
}


export const formatWithCommas = (num) => {
  // Convert the number to a string for manipulation
  if (isNaN(num) || num === null || num === undefined) {
    return '0';
  }

  const numStr = num.toString();

  // Split into integer and decimal parts if needed
  const [integerPart, decimalPart] = numStr.split('.');

  // Format the integer part with commas
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine integer and decimal parts if a decimal exists
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}


export const getMonthName = (monthNumber) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Check if the input is valid
  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number. Please enter a number between 1 and 12.";
  }

  // Return the month name
  return months[monthNumber - 1];
}


export const extractMonth = (dateString) => {
  // Ensure the input is a valid date string in the format "YYYY-MM-DD"
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    throw new Error("Invalid date format. Expected format: YYYY-MM-DD");
  }

  // Split the string to extract the month
  const month = dateString.split("-")[1];

  // Return the month as a number
  return parseInt(month, 10);
}


export const formatDateToYearMonth = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
  return `${year}-${month}`;
}


export function convertDateIfNecessary(dateString) {
  const targetFormatRegex = /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4}$/;

  // Check if the date is in the target format
  if (targetFormatRegex.test(dateString)) {
    // Parse the date
    const parsedDate = new Date(dateString);
    if (isNaN(parsedDate)) {
      return dateString
    }

    // Format the date to "YYYY-MM-DD"
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(parsedDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  // Return the string unchanged if it's not in the target format
  return dateString;
}
