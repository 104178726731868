import React, { useEffect, useState } from "react";
import plus from "assets/plus.png";
import { CSS } from "@dnd-kit/utilities";
import { Table } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { TabsView } from "./tabView";
import { DndContext } from "@dnd-kit/core";
import { MenuOutlined } from "@ant-design/icons";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { DeleteFilled } from "@ant-design/icons";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSelector } from "react-redux";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
        position: "relative",
        zIndex: 9999,
      }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <>
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              </>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const ActivityFieldsTable = ({
  setModalOpen,
  dataSource,
  setDataSource,
  expandedRowKeys,
  setExpandedRowKeys,
  browseField,
  setExpandedIndex,
  setCurrentItem,
  setFieldsModel,
  currentItem,
  handleSelectType,
  handleDuplicateField,
  selectedItem,
  setSelectedItem,
}) => {

  const ThemeColor = useSelector((state) => state?.common?.Theme);

  const onDragEnd = (event, index) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activeIndex = dataSource[index].fields.findIndex(
        (i) => i.key === active.id
      );
      const overIndex = dataSource[index].fields.findIndex(
        (i) => i.key === over?.id
      );
      const sortedArray = arrayMove(
        dataSource[index].fields,
        activeIndex,
        overIndex
      );
      setDataSource((prevArray) => {
        const newArray = [...prevArray];
        newArray[index].fields = sortedArray;
        return newArray;
      });
    }
  };

  const columns = (index) => [
    {
      title: "Sort",
      key: "sort",
      dataIndex: "index",
      render: (text, record) => {
        return dataSource?.indexOf(record) + 1;
      },
    },
    {
      title: "Field Label",
      render: (text, record, rowindex) => {
        return (
          <>
            <div>{record.label}</div>
            <div className="text-xs">
              <button
                onClick={() => {
                  handleDuplicateField(index, rowindex);
                }}
              >
                Duplicate
              </button>
              .
              <button
                onClick={() => {
                  let newData = [...dataSource];
                  newData = newData.map((item) => ({
                    ...item,
                    fields: item.fields.filter(
                      (field) => field.id !== record.id
                    ),
                  }));
                  setDataSource(newData);
                }}
              >
                <span className="text-red-500">Delete</span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      title: "Place Holder",
      dataIndex: "placeHolder",
    },
    {
      title: "Type",
      dataIndex: "fieldType",
      key: "fieldType",
    },
  ];
  const handleField = (index) => {
    let newField = {
      id: Math.random(),
      key: Math.random(),
      ConditionalLogic: false,
      fieldType: "text",
      isUpdate: false,
      name: "",
      label: "Label",
      xmlName: "name",
      popupText: "",
      helpNote: "",
      placeHolder: "Place Holder",
      errorMessage: "",
      regex: "",
      value: "",
      minLength: 30,
      isRequired: false,
      isEdit: false,
      isDisabled: false
    };
    const newState = [...dataSource];
    newState[index].fields.push(newField);
    setDataSource(newState);
  };

  const onChange = (e, index) => {
    let value = e.target.value;
    if (value.length < 30) {
      const newState = [...dataSource];
      newState[index].groupName = value;
      setDataSource(newState);
    }
  };
  const handleDeleteGroup = (key) => {
    const newState = [...dataSource];
    const deleteItem = newState.filter((item) => {
      return item.key !== key;
    });
    setDataSource(deleteItem);
  };
  return (
    <div className="draggable-table-container w-full pr-2 pl-2 ">
      {dataSource?.map((item, index) => {
        return (
          <div className="mb-4 card-board w-full  overflow-x-auto">
            <div className="flex w-full justify-between mt-4 mb-4 pr-2 pl-2">
              <div className="flex items-center">
                <div>
                  <input
                    type="text"
                    value={item.groupName}
                    className="h-8 w-56 border-none px-2 py-1 outline-none text-sm paragraphs_font"
                    placeholder="Enter Group Name Here"
                    onChange={(e) => {
                      onChange(e, index);
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  className="card-board p-1 flex items-center paragraphs_font hover:bg-red-300 hover:text-black mr-2 rounded"
                  onClick={() => {
                    handleDeleteGroup(item.key);
                  }}
                >
                  <span>
                    <DeleteFilled className="text-red-500 mr-1" />
                  </span>
                  Delete
                </button>
                {item?.isPricing !== true && <button
                  className="card-board p-1 flex items-center paragraphs_font hover:bg-blue-300 hover:text-white rounded"
                  onClick={() => handleField(index)}
                >
                  <span>
                    <img src={plus} alt="plus_icon" className="h-4 mr-1" />
                  </span>
                  Add Field
                </button>}
              </div>
            </div>
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={(event) => onDragEnd(event, index)}
            >
              <SortableContext
                items={item && item?.fields.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  className={`draggable-table ${ThemeColor ? "" : "draggable_dark_theme"}`}
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  rowKey="key"
                  // columns={columns}
                  columns={columns(index)}
                  expandIconColumnIndex={1}
                  expandIconAsCell={false}
                  dataSource={[...item.fields]}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (record) => {
                      return (
                        <TabsView
                          setModalOpen={setModalOpen}
                          dataSource={[...item.fields]}
                          setDataSource={setDataSource}
                          expandedRowKeys={expandedRowKeys}
                          recordId={record?.id}
                          setExpandedIndex={setExpandedIndex}
                          originalData={dataSource}
                          setFieldsModel={setFieldsModel}
                          currentItem={item}
                          setCurrentItem={setCurrentItem}
                          handleSelectType={handleSelectType}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                        />
                      );
                    },
                    onExpand: (expanded, record) => {
                      if (expanded) {
                        setExpandedRowKeys([...expandedRowKeys, record.id]);
                      } else {
                        setExpandedRowKeys(
                          expandedRowKeys.filter((key) => key !== record.id)
                        );
                      }
                    },
                  }}
                />
              </SortableContext>
            </DndContext>
          </div>
        );
      })}
    </div>
  );
};
