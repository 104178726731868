import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import homes from "assets/Homes.svg";
import management from "assets/Management.svg";
import management_color from "assets/Management_Color.svg";
import profit from "assets/profit.svg";
import dashboard_dark from "assets/Dashboard_Dark.svg";
import dashboard_color from "assets/Dashboard_Color.svg";
import sun from "assets/sun.svg";
import moon from "assets/moon.svg";
import geo_fence from "assets/Geo_Fence.svg";
import svg_logo from "../../../assets/svg_logo.svg";
import pop from "assets/POP.svg";
import pop_color from "assets/POP_Color.svg";
import geo_fences_color from "assets/Geo_Fences_Color.svg";
import feature from "assets/feature.svg";
import feature_color from "assets/Feature_Color.svg";
import finance from "assets/finance.svg";
import finance_color from "assets/finance_light.svg";
import monitoring from "assets/Monitoring.svg";
import monitoring_color from "assets/Monitoring_Color.svg";
import activity from "assets/Activity.svg";
import activity_color from "assets/Activity_Color.svg";
import organization from "assets/Organization.svg";
import organizations_color from "assets/Organizations_Color.svg";
import speech_to_text from "assets/Speech_To_Text.svg";
import speech_text from "assets/Speech_Text.svg";
import reporting_dark from "assets/Reporting_Dark.svg";
import reporting from "assets/Reporting.svg";
import games from "assets/Games.svg";
import games_color from "assets/Games.svg";
import announcement from "assets/announcement.svg";
import announcement_color from "assets/Announcement_Color.svg";
import { MainMenu } from "utils/constants/SibarBarMenu";
import { setCookie, getCookie } from "services/session/cookies";
import { ChangeTheme } from "services/api/common";
import { GenericTooltip } from "components";
import { useTranslation } from "react-i18next";
import { DropDownFiltration, resetFilters } from "store/actions/Filters";
import { Permissions } from "utils/constants/Permissions/features";
import { IsShownEnum } from "utils/constants/SideBarConstants";
import { useSelector } from "react-redux";
import { UserTypeEnum } from "utils/constants/UserManagement";
import { activityChange } from "store/actions/DDS";
import { OnlyInclude } from "utils/helpers/common";
import { ActivityTypeEnum } from "utils/constants/DDsDashboard";
import { ChangeNavigation } from "store/actions/common";

const Sidebar = ({ ThemeColor, setSideNavigationShow, sideNavigationShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShown, setIsShown] = useState();
  const permissions = getCookie("permissions" ? "permissions" : []);
  const userInformation = useSelector((state) => state.auth.user);

  const [t, i18n] = useTranslation("global");
  const changeThemeColor = (value) => {
    dispatch(ChangeTheme(value));
  };

  const toggleThemeColor = (isLightMode) => {
    if (isLightMode === true) {
      localStorage.setItem("ThemeColor", "light");
      changeThemeColor(isLightMode);
    } else {
      localStorage.setItem("ThemeColor", "dark");
      changeThemeColor(isLightMode);
    }
  };

  const NavItem = () => {
    switch (location.pathname) {
      case "/user-management":
        return setIsShown(IsShownEnum.User_Management);
      case "/program-management":
        return setIsShown(IsShownEnum.Program_Management);
      case "/organization-management":
        return setIsShown(IsShownEnum.Organization_Management);
      case "/pop-type-management":
        return setIsShown(IsShownEnum.Pop_Type_Management);
      case "/reporting":
        return setIsShown(IsShownEnum.Reporting);
      case "/geo-fence":
        return setIsShown(IsShownEnum.Geo_Fence);
      case "/games":
        return setIsShown(IsShownEnum.Games);
      case "/feature-management":
        return setIsShown(IsShownEnum.Feature_Management);
      case "/announcement":
        return setIsShown(IsShownEnum.Announcement);
      case "/speech-checking":
        return setIsShown(IsShownEnum.Speech_Checking);
      case "/translate-uploader":
        return setIsShown(IsShownEnum.translate_uploader);
      case "/monitoring":
        return setIsShown(IsShownEnum.Monitoring);
      default:
        return setIsShown(null);
    }
  };

  useEffect(() => {
    NavItem();
  }, []);

  const performBeforeNavigation = async (destination) => {
    try {
      await dispatch(resetFilters());
      await dispatch(
        DropDownFiltration({
          organization: "",
          program: "",
          activity: "",
          form: "",
          fieldStaff: "",
          fieldStaffGroup: "",
          geoFence: "",
          site: "",
          branch: "",
          filterby: "default",
          filterMode: "multiple",
        })
      );
      navigate(destination);
    } catch (error) {
      console.error("Error performing actions before navigation:", error);
    }
  };

  // const handleEvent = (key, destination) => {
  //   const selectedItem = MainMenu.find((item) => item.key === key);
  //   if (selectedItem) {
  //     const { label, link, items } = selectedItem;
  //     const mappedItems = items
  //       ? items.map((item) => {
  //         return {
  //           label: item.label,
  //           value: item.link,
  //           permission: item.permission,
  //         };
  //       })
  //       : [];
  //     let result = [{ label, value: link }];
  //     if (mappedItems.length > 0 && permissions.length > 0) {
  //       const checkPermissions = mappedItems.filter((item) => {
  //         return JSON.parse(permissions).includes(item.permission);
  //       });
  //       result = result.concat(checkPermissions);
  //     } else {
  //       result = result.concat(mappedItems);
  //     }
  //     setCookie("IsShownEnum", JSON.stringify(result));
  //   }
  //   performBeforeNavigation(destination);
  // };

  // const pathChange = () => {
  //   const selectedItem = MainMenu.find(
  //     (item) => item.link === location.pathname
  //   );
  //   if (selectedItem) {
  //     const { label, link, items } = selectedItem;
  //     const mappedItems = items
  //       ? items.map((item) => {
  //         return {
  //           label: item.label,
  //           value: item.link,
  //           permission: item.permission,
  //         };
  //       })
  //       : [];
  //     let result = [{ label, value: link }];
  //     if (mappedItems.length > 0 && permissions.length > 0) {
  //       const checkPermissions = mappedItems.filter((item) => {
  //         return JSON.parse(permissions).includes(item.permission);
  //       });
  //       result = result.concat(checkPermissions);
  //     } else {
  //       result = result.concat(mappedItems);
  //     }
  //     setCookie("IsShownEnum", JSON.stringify(result));
  //   }
  // };

  const handleEvent = async (key, destination) => {
    const selectedItem = MainMenu.find((item) => item.key === key);
    if (selectedItem) {
      const { label, link, items } = selectedItem;
      const mappedItems = items
        ? items.map((item) => {
          return {
            label: item.label,
            value: item.link,
            permission: item.permission,
          };
        })
        : [];
      let result = [{ label, value: link }];
      if (mappedItems.length > 0 && permissions.length > 0) {
        const checkPermissions = mappedItems.filter((item) => {
          return JSON.parse(permissions).includes(item.permission);
        });
        result = result.concat(checkPermissions);
      } else {
        result = result.concat(mappedItems);
      }
      await setCookie("navItems", JSON.stringify(result));
      dispatch(
        ChangeNavigation(
          result
        )
      )
    }
    performBeforeNavigation(destination);
  };

  const pathChange = () => {
    const selectedItem = MainMenu.find(
      (item) => item.link === location.pathname
    );
    if (selectedItem) {
      const { label, link, items, key, childLink } = selectedItem;
      const mappedItems = items
        ? items.map((item) => {
          return {
            label: item.label,
            value: item.link,
            key: item.key,
            permission: item.permission,
          };
        })
        : [];
      const childItems = childLink
        ? childLink.map((item) => {
          return {
            child: true,
            key: item.key,
            value: item.value,
            permission: item.permission,
          };
        })
        : [];
      let result = [{ label, value: link, key: key }];
      if (childItems.length > 0 && permissions.length > 0) {
        const checkPermissions = childItems.filter((item) => {
          return JSON.parse(permissions).includes(item.permission);
        });
        result = result.concat(checkPermissions);
      }
      if (mappedItems.length > 0 && permissions.length > 0) {
        const checkPermissions = mappedItems.filter((item) => {
          return JSON.parse(permissions).includes(item.permission);
        });
        result = result.concat(checkPermissions);
      } else {
        result = result.concat(mappedItems);
      }
      setCookie("navItems", JSON.stringify(result));
      dispatch(
        ChangeNavigation(
          result
        )
      )
    }
  };

  const findMenuByPath = (menu, path) => {
    for (const item of menu) {
      if (item.link === path) {
        return item;
      }
      if (item.items && Array.isArray(item.items)) {
        for (const item2 of item.items) {
          if (item2.link === path) {
            return item;
          }
        }
      }
    }
    return null;
  }

  const pathCheckHighlight = () => {
    const selectedMenu = findMenuByPath(MainMenu, location.pathname);
    if (selectedMenu) {
      activeItem(selectedMenu?.showKey)
    }
  }

  useEffect(() => {
    if (location.pathname) {
      pathChange();
      pathCheckHighlight()
    }
  }, [location.pathname]);

  const activeItem = (showKey) => {
    setIsShown(Number(showKey));
  };

  return (
    <>
      <div
        className={`text-Color h-dvh w-siderbar fixed top-0 bottom-0 pt-2 flex flex-col items-center justify-between z-30 bg-black overflow-y-auto`}
      >
        <div className="flex flex-col space-y-3 items-center justify-between">
          <div className="pb-5">
            <Link to="/main-dashboard">
              <img src={svg_logo} alt="svg_logo" />
            </Link>
          </div>
          {/* <div
            className="pt-3 cursor-pointer"
            onClick={() => {
              setSideNavigationShow(!sideNavigationShow);
            }}
          >

            <img src={grid} alt="svg_logo" />
          </div> */}
          {!(JSON.parse(permissions).every(permission => OnlyInclude.includes(permission))) && <div
            className="pt-3"
            onClick={() => handleEvent("Z01", "/main-dashboard")}
          >
            <GenericTooltip
              placement="right"
              title={t("sidebar.home")}
              content="content"
              className="bg-Color text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/main-dashboard">
                <img src={homes} alt="home_icon" className="cursor-pointer" />
              </Link>
            </GenericTooltip>
          </div>}
          {(JSON.parse(permissions).includes(Permissions.DDS_DS) || JSON.parse(permissions).includes(Permissions.INSTORE_DS) || JSON.parse(permissions).includes(Permissions.SUP_DS)) && (
            <div
              className="p-1  cursor-pointer rounded h-[32px] w-[32px]"
              onClick={() => {
                handleEvent("Z01", JSON.parse(permissions).includes(Permissions.DDS_DS) ? "/dds-dashboard" : JSON.parse(permissions).includes(Permissions.INSTORE_DS) ? "/in-store-dashboard" : "/supervisor-performance-dashboard");
                dispatch(
                  activityChange({
                    activityType: JSON.parse(permissions).includes(Permissions.DDS_DS) ? ActivityTypeEnum.DTD : JSON.parse(permissions).includes(Permissions.INSTORE_DS) ? ActivityTypeEnum.INSTORE : ActivityTypeEnum.SUPERVISORPERFORMANCE
                  })
                );
              }
              }
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.analytics")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link
                  to={JSON.parse(permissions).includes(Permissions.DDS_DS) ? "/dds-dashboard" : JSON.parse(permissions).includes(Permissions.INSTORE_DS) ? "/in-store-dashboard" : "/supervisor-performance-dashboard"}
                // className={`${isShown == IsShownEnum.Dashboard ? "bg-[#313537]" : "bg-transparent"} rounded-lg p-1`}
                >
                  <img
                    src={
                      isShown === IsShownEnum.Dashboard
                        ? dashboard_color
                        : dashboard_dark
                    }
                    alt="activity_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Dashboard);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>)}
          {JSON.parse(permissions).includes(Permissions.ACTIVITY_MANAGEMENT) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Program_Management ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("B02", "/program-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.programManagement")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link
                  to="/program-management"
                >
                  <img
                    // src={isShown === 2 ? Activity_Color : Activity}
                    src={
                      isShown === IsShownEnum.Program_Management
                        ? activity_color
                        : activity
                    }
                    alt="activity_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Program_Management);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}

          {JSON.parse(permissions).includes(Permissions.USER_MANAGEMENT) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.User_Management ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("C03", "/user-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.userManagement")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/user-management">
                  <img
                    src={
                      isShown === IsShownEnum.User_Management
                        ? management_color
                        : management
                    }
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.User_Management);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes(
            Permissions.ORGANIZATION_MANAGEMENT
          ) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Organization_Management ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("D04", "/organization-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.organizationManagement")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/organization-management">
                  <img
                    src={
                      isShown === IsShownEnum.Organization_Management
                        ? organizations_color
                        : organization
                    }
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Organization_Management);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}

          {JSON.parse(permissions).includes(Permissions.POP_MANAGEMENT) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Pop_Type_Management ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("E05", "/pop-type-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.POPManagement")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/pop-type-management">
                  <img
                    src={
                      isShown === IsShownEnum.Pop_Type_Management
                        ? pop_color
                        : pop
                    }
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Pop_Type_Management);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes(
            Permissions.REPORTING_MANAGEMENT
          ) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Reporting ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("G01", "/reporting")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.reporting")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/reporting">
                  <img
                    src={
                      isShown === IsShownEnum.Reporting
                        ? reporting
                        : reporting_dark
                    }
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Reporting);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes(
            Permissions.FINANCE_MODULE
          ) && (
              <div
                className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Finance_Management ? "bg-[#313537]" : "bg-transparent"}`}
                onClick={() => handleEvent("G01", "/finance-management")}
              >
                <GenericTooltip
                  placement="right"
                  title={t("sidebar.finance")}
                  content="content"
                  className="bg-Color text-black"
                  arrow={{ arrowPointAtCenter: true }}
                >
                  {/* <Link to="/finance-management"> */}
                  <img
                    src={
                      isShown === IsShownEnum.Finance_Management
                        ? finance_color
                        : finance
                    }
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Finance_Management);
                    }}
                  />
                  {/* </Link>/ */}
                </GenericTooltip>
              </div>
            )}
          {JSON.parse(permissions).includes(
            Permissions.MONITORING_MANAGEMENT
          ) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Monitoring ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("H01", "/monitoring")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.monitoring")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/monitoring">
                  <img
                    src={
                      isShown === IsShownEnum.Monitoring
                        ? monitoring_color
                        : monitoring
                    }
                    sv
                    alt="home_icon"
                    className="cursor-pointer bg-color[#6F767E]"
                    onClick={() => {
                      activeItem(IsShownEnum.Monitoring);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes(
            Permissions.GEO_FENCE_MANAGEMENT
          ) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Geo_Fence ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("F01", "/geo-fence")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.geoFencing")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/geo-fence">
                  <img
                    src={
                      isShown === IsShownEnum.Geo_Fence
                        ? geo_fences_color
                        : geo_fence
                    }
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Geo_Fence);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {/* {JSON.parse(permissions).includes("FM_72") ? ( */}
          {!(JSON.parse(permissions).every(permission => OnlyInclude.includes(permission))) && <div
            className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Feature_Management ? "bg-[#313537]" : "bg-transparent"}`}
            onClick={() => handleEvent("F02", "/feature-management")}
          >
            <GenericTooltip
              placement="right"
              title={t("sidebar.featureManagement")}
              content="content"
              className="bg-Color text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/feature-management">
                <img
                  src={
                    isShown === IsShownEnum.Feature_Management
                      ? feature_color
                      : feature
                  }
                  alt="home_icon"
                  className="cursor-pointer"
                  onClick={() => {
                    activeItem(IsShownEnum.Feature_Management);
                  }}
                />
              </Link>
            </GenericTooltip>
          </div>}
          {/* ) : (
            ""
          )} */}
          {JSON.parse(permissions).includes(Permissions.GAME_MANAGEMENT) ? (
            <div
              className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Games ? "bg-[#313537]" : "bg-transparent"}`}
              onClick={() => handleEvent("I01", "/games")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.gamesManagement")}
                content="content"
                className="bg-Color text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/games">
                  <img
                    src={isShown === IsShownEnum.Games ? games_color : games}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem(IsShownEnum.Games);
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {!(JSON.parse(permissions).every(permission => OnlyInclude.includes(permission))) && <div
            className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Announcement ? "bg-[#313537]" : "bg-transparent"}`}
            onClick={() => handleEvent("J01", "/announcement")}
          >
            <GenericTooltip
              placement="right"
              title={t("sidebar.announcement")}
              content="content"
              className="bg-Color text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/announcement">
                <img
                  src={
                    isShown === IsShownEnum.Announcement
                      ? announcement_color
                      : announcement
                  }
                  alt="home_icon"
                  className="cursor-pointer"
                  onClick={() => {
                    activeItem(IsShownEnum.Announcement);
                  }}
                />
              </Link>
            </GenericTooltip>
          </div>}

          {/* {!(JSON.parse(permissions).every(permission => OnlyInclude.includes(permission))) && <div
            className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.Speech_Checking ? "bg-[#313537]" : "bg-transparent"}`}
            onClick={() => handleEvent("H01", "/speech-checking")}
          >
            <GenericTooltip
              placement="right"
              title={t("sidebar.speechToText")}
              content="content"
              className="bg-Color text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/speech-checking">
                <img
                  src={isShown === IsShownEnum.Speech_Checking ? speech_text : speech_to_text}
                  alt="home_icon"
                  className="cursor-pointer"
                  onClick={() => {
                    activeItem("8");
                  }}
                />
              </Link>
            </GenericTooltip>
          </div>}
          {userInformation.userType === UserTypeEnum.ADMIN && <div
            className={`p-1  cursor-pointer rounded-lg h-[28px] w-[28px] ${isShown == IsShownEnum.translate_uploader ? "bg-[#313537]" : "bg-transparent"}`}
            onClick={() => handleEvent("H01", "/translator-upload")}
          >
            <GenericTooltip
              placement="right"
              title={t("sidebar.translateUpload")}
              content="content"
              className="bg-Color text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/translator-upload">
                <img
                  src={isShown === IsShownEnum.translate_uploader ? speech_text : speech_to_text}
                  alt="home_icon"
                  className="cursor-pointer"
                  onClick={() => {
                    activeItem("16");
                  }}
                />
              </Link>
            </GenericTooltip>
          </div>} */}
        </div>

        <div
          className="pb-4 flex items-center flex-col mt-8"
          onClick={() => setIsShown(7)}
        >
          <div>
            <GenericTooltip
              placement="right"
              title={t("sidebar.changeTheme")}
              content="content"
              ClassName="bg-Color text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              {ThemeColor ? (
                <img
                  src={moon}
                  alt="Settings_logo"
                  className="cursor-pointer"
                  onClick={() => toggleThemeColor(false)}
                />
              ) : (
                <img
                  src={sun}
                  alt="Settings_logo"
                  className="cursor-pointer"
                  onClick={() => toggleThemeColor(true)}
                />
              )}
            </GenericTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
