import { UPDATEFILTERS, RESETFILTERS, SETRAWDATA, DROPDOWNFILTRATION, SPECIFICFILTERS } from "./Types";

export function updateFilters(data) {
  return {
    type: UPDATEFILTERS,
    data,
  };
}

export function resetFilters(data) {
  return {
    type: RESETFILTERS,
    data,
  };
}


export function specificFilters(data) {
  return {
    type: SPECIFICFILTERS,
    data,
  };
}

export function setRawData(data) {
  return {
    type: SETRAWDATA,
    data,
  };
}

export function DropDownFiltration(data) {
  return {
    type: DROPDOWNFILTRATION,
    data,
  };
}