import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function ReportingColumnReducer(state = StoreState.ReportingColumn, action) {
    switch (action.type) {
        case ActionType.REPORTINGFORM:
            const s = cloneDeep(state);
            s.form = action?.data?.form;
            return s;

        case ActionType.REPORTINGCOULMN:
            const s2 = cloneDeep(state);
            s2.columns = action?.data?.columns;
            return s2;

        default:
            return state;
    }
}
