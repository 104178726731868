import React, { useState } from "react";
import { Input } from "@nextui-org/react";
import { motion } from "framer-motion";
import password_icon from "assets/password_icon.svg";
import password_icon_show from "assets/password_icon_show.svg";

export const InputsField = ({
  type = "text",
  name = "",
  id = "",
  value = "",
  label = "",
  disabled,
  changeHandler,
  autoFocus = false,
  required = false,
  placeholder = "",
  labelClass,
  ThemeColor,
  blackTheme = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    if (type === "number") {
      if (inputValue === "" || /^[0-9]*\.?[0-9]*$/.test(inputValue)) {
        changeHandler(e);
      }
    } else {
      changeHandler(e);
    }
  };

  return (
    <div className={`relative w-full ${!blackTheme ? "input-field-container" : "input-Datefield-container-dark-theme"} ${disabled && "opacity-50"}`}>
      {/* <motion.label
        initial={{ y: 0, scale: 1 }}
        animate={{
          y: isFocused || value ? 0 : 15,
          x: isFocused || value ? 0: 0,
          // scale: isFocused || value ? 0.75 : 1,
        }}
        transition={{ duration: 0.3 }}
        className={`absolute left-2 transition-all ${labelClass} paragraphs_font  ${isFocused? "field-focused":"field-label"}`}
        style={{ pointerEvents: "none" }}
      >
        {label} {required? <span className="text-red-600">*</span> : ""}
      </motion.label>
      <Input
        clearable
        autoComplete="off"
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={isFocused ? "" : placeholder} // Hide placeholder when label is animated
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={changeHandler}
        required={required}
        className="pt-6 pb-2 border rounded w-full text-white outline-none"
      /> */}
      <Input
        clearable
        autoComplete="off"
        autocomplete="none"
        autoFill="off"
        autoFocus={autoFocus}
        // type={type}
        name={name}
        id={id}
        value={value}
        label={label}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleChange}
        isRequired={required}
        type={type === "password" ? (isVisible ? "text" : "password") : type}
        className={{
          Input: [`${isFocused ? "field-focused" : "field-label"}`],
          innerWrapper: "bg-transparent",
          inputWrapper: [
            `${isFocused ? "field-focused" : "field-label"}`,
            "rounded",
            "w-full",
            "text-white",
            "outline-none",
            "group-data-[focus=true]:bg-default-200/50",
            "dark:group-data-[focus=true]:bg-default/60",
          ],
        }}
        // type={isVisible ? "text" : "password"}
        endContent={
          <>
            {type === "password" ? (
              <button
                className="focus:outline-none self-center"
                type="button"
                onClick={toggleVisibility}
                aria-label="toggle password visibility"
              >
                {isVisible ? (
                  <img src={password_icon_show} alt="eye-icon" />
                ) : (
                  <img src={password_icon} alt="eye-icon" />
                )}
              </button>
            ) : (
              ""
            )}
          </>
        }
      />
    </div>
  );
};
